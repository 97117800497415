/*
 * As it's quite complex to create this with Tailwind, I prefer
 * to go with CSS Modules. By: Gabriel.
 * 
 * CodePen Home Outline Float Label Pure CSS
 * https://codepen.io/asasaki/pen/ZEzxGOO
 *
 */

.box {
  position: relative;
  border-radius: theme('borderRadius.sm');
}

.input {
  transition: all 0.2s;
  touch-action: manipulation;
  cursor: text;
  background-color: white;
  border-radius: theme('borderRadius.sm');
  border: 1px solid theme('borderColor.gray.2');
  width: 100%;
  height: theme('height.11');
  padding: 0 10px;
  color: theme('colors.secondary');
  font-weight: 400;
  appearance: none;
}

.label {
  position: absolute;
  top: 20px;
  left: 12px;
  color: theme('colors.gray.1');
  font-size: 14px;
  transition: all 0.2s;
  /*enable click everywhere inside input */
  pointer-events: none;
}

.input::placeholder {
  opacity: 0;
  color: black;
  font-size: 17.5px;
}

/*make the placeholder disappear on focus*/
.input:focus::placeholder {
  opacity: 1;
  color: theme('colors.gray.2');
  font-weight: 300;
}

.input:placeholder-shown + .label {
  cursor: text;
  max-width: 66.66%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform-origin: left bottom;
  transform: translate(0, 0) scale(1.15);
}

.input:focus + .label,
.input:not(:placeholder-shown) + .label {
  transform: translate(-2px, -31px) scale(1);
  padding: 0 3px;
}

.input:focus + .label::after,
.input + .label::after {
  content: '';
  display: block;
  position: absolute;
  background: white;
  height: 3px;
  top: 50%;
  left: -0.2em;
  right: -0.2em;
  z-index: -1;
}

.input:not(:placeholder-shown) + .label::after {
  left: -0.1em;
  right: -0.1em;
}

/* Validations */

.input.valid:focus {
  outline: solid 1px theme('colors.primary.DEFAULT');
  border-color: theme('colors.primary.DEFAULT');
}

.input.valid:focus + label {
  color: theme('colors.primary.DEFAULT');
}

.input.invalid:focus {
  outline: solid 1px theme('colors.danger.DEFAULT');
  border-color: solid 1px theme('colors.danger.DEFAULT');
}

.input.invalid:focus + label {
  color: theme('colors.danger.DEFAULT');
}

.input.invalid {
  border-color: theme('colors.danger.DEFAULT');
}

.input.invalid + label {
  color: theme('colors.danger.DEFAULT');
}

.input:not(:disabled).valid:not(:placeholder-shown) {
  border-color: theme('colors.primary.DEFAULT');
}

.input:not(:disabled).valid:not(:placeholder-shown) + label {
  color: theme('colors.primary.DEFAULT');
}

.input:disabled {
  background-color: #f5f5f5;
  color: theme('colors.gray.6');
}

.input:disabled + label::after {
  background-color: #f5f5f5;
}
